var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Box, Button, Flex, Select, Spacer } from '@chakra-ui/react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MisrepTitle from '@/pages/policy-management/view/[policyReference]/MisRep/MisrepTitle/MisrepTitle';
import SearchBox from '@/components/Document/SearchBox/SearchBox';
import useTenantContext from '@/domain/Tenancy/hooks/useTenantContext/useTenantContext';
import PolicySummaryHeader from '@/pages/policy-management/view/[policyReference]/PolicySummaryHeader/PolicySummaryHeader';
import { useAuthToken } from '@/domain/Auth/hooks/useAuthToken/useAuthToken';
import { downloadEnquiryComparisonResultsAsFile, downloadMisrepResultsAsFile, getMisrepResults, } from '@/domain/Misrep/MisrepService';
import MisRepSection from './MisrepSection/MisrepSection';
import { RunStatus } from '@/domain/Run/Run';
import useGetPolicyByReference from '@/domain/Policy/hooks/useGetPolicyByReference/useGetPolicyByReference';
export var MisRepContext = React.createContext({
    fetchAndSetResults: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/];
    }); }); },
});
var MisRepReport = function (_a) {
    var runId = _a.runId, policyReference = _a.policyReference;
    var isMounted = useRef(false);
    var tenant = useTenantContext().tenant;
    var token = useAuthToken().token;
    var t = useTranslation(['policy-management/misrep']).t;
    var _b = useState(false), isDataLoaded = _b[0], setIsDataLoaded = _b[1];
    var _c = useState([]), misrepresentation = _c[0], setMisrepresentation = _c[1];
    var _d = useState([]), noMisrepFound = _d[0], setNoMisrepFound = _d[1];
    var _e = useGetPolicyByReference(policyReference, tenant, token), data = _e.data, error = _e.error, isLoading = _e.isLoading, refetch = _e.refetch;
    var handleDownloadEnquiryComparisonReport = function () { return __awaiter(void 0, void 0, void 0, function () {
        var enquiryId, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    enquiryId = data === null || data === void 0 ? void 0 : data.enquiryId;
                    if (enquiryId === undefined || enquiryId == null) {
                        return [2 /*return*/];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, downloadEnquiryComparisonResultsAsFile({
                            policyReference: policyReference,
                            runId: runId,
                            tenant: tenant,
                            token: token,
                        })];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    console.error('Error downloading comparison file:', error_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var MisrepColours;
    (function (MisrepColours) {
        MisrepColours["MISREP"] = "brand.red";
        MisrepColours["NO_MISREP"] = "brand.green";
        MisrepColours["NO_RECORD"] = "cta.secondary";
    })(MisrepColours || (MisrepColours = {}));
    var onSearch = function (searchTerm) {
        // TODO:
    };
    var handleDownloadMisrepReport = function () { return __awaiter(void 0, void 0, void 0, function () {
        var error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, downloadMisrepResultsAsFile({
                            policyReference: policyReference,
                            runId: runId,
                            tenant: tenant,
                            token: token,
                        })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    error_2 = _a.sent();
                    console.error('Error downloading file:', error_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var fetchMisrepResults = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getMisrepResults({
                        policyReference: policyReference,
                        runId: runId,
                        tenant: tenant,
                        token: token,
                    })];
                case 1:
                    data = _a.sent();
                    return [2 /*return*/, data];
            }
        });
    }); }, [policyReference, runId, tenant, token]);
    var isMisrepresentation = function (result) {
        return result.foundedEvidences.length > 0 &&
            result.foundedEvidences.filter(function (evidence) { return !evidence.disagreed; }).length > 0;
    };
    var isVerified = function (result) {
        return result.foundedEvidences.length === 0 ||
            result.foundedEvidences.filter(function (evidence) { return !evidence.disagreed; }).length ===
                0;
    };
    var categorizeMisrep = function (data) {
        var newMisrepresentation = [];
        var newNoMisrepFound = [];
        data.forEach(function (result) {
            if (isVerified(result)) {
                newNoMisrepFound.push(result);
            }
            else if (isMisrepresentation(result)) {
                newMisrepresentation.push(result);
            }
        });
        return { newMisrepresentation: newMisrepresentation, newNoMisrepFound: newNoMisrepFound };
    };
    var fetchAndSetResults = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, _a, newMisrepresentation, newNoMisrepFound;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, fetchMisrepResults()];
                case 1:
                    data = _b.sent();
                    _a = categorizeMisrep(data), newMisrepresentation = _a.newMisrepresentation, newNoMisrepFound = _a.newNoMisrepFound;
                    if (isMounted.current) {
                        setNoMisrepFound(newNoMisrepFound);
                        setMisrepresentation(newMisrepresentation);
                        setIsDataLoaded(true);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var getRunMisrepStatus = useCallback(function () {
        var isThereAnyActiveMisrep = misrepresentation.filter(function (result) {
            return result.foundedEvidences.find(function (result) { return !result.disagreed; });
        }).length;
        return isThereAnyActiveMisrep === 0
            ? RunStatus.PASSED.toString()
            : RunStatus.MISREP.toString();
    }, [misrepresentation]);
    useEffect(function () {
        isMounted.current = true;
        fetchAndSetResults();
        refetch();
        return function () {
            isMounted.current = false;
        };
    }, []);
    return (<>
      <MisRepContext.Provider value={{ fetchAndSetResults: fetchAndSetResults }}>
        <Flex justifyContent="center" backgroundColor="white">
          {isDataLoaded && (<MisrepTitle policyReference={policyReference} status={getRunMisrepStatus()}/>)}
        </Flex>
        <Flex justifyContent="space-between" sx={{
        paddingTop: '10px',
        paddingBottom: '10px',
        backgroundColor: 'white',
        paddingLeft: '200px',
        paddingRight: '200px',
    }}>
          <PolicySummaryHeader isMale name="Policy Holder" age={46}/>
          <Flex justifyContent="space-between" sx={{
        paddingTop: '10px',
        paddingBottom: '10px',
        backgroundColor: 'white',
    }}>
            {(data === null || data === void 0 ? void 0 : data.enquiryId) !== undefined && (data === null || data === void 0 ? void 0 : data.enquiryId) !== null && (<Box alignSelf="center">
                <Button onClick={function () { return handleDownloadEnquiryComparisonReport(); }}>
                  {t('policy-management/summary:POLICY_HEADER.TITLES.DOWNLOAD_ENQUIRY')}
                </Button>
              </Box>)}
            <Box alignSelf="center">
              <Button onClick={handleDownloadMisrepReport}>
                {t('policy-management/summary:POLICY_HEADER.TITLES.DOWNLOAD_REPORT')}
              </Button>
            </Box>
          </Flex>
        </Flex>
        <Flex sx={{
        flexDirection: 'row',
        backgroundColor: 'brand.grey.100',
        paddingTop: '15px',
        paddingBottom: '5px',
        paddingLeft: '200px',
        paddingRight: '200px',
    }}>
          <Box sx={{
        minW: '500px',
    }}>
            <SearchBox onSearch={onSearch} placeholder={t('common:SEARCH_PLACEHOLDER')}/>
          </Box>
          <Spacer />
          <Box sx={{
        paddingLeft: '10px',
    }}>
            <div style={{ float: 'left', paddingTop: '8px', paddingRight: '5px' }}>
              {t('common:FILTER')}
            </div>
            <div style={{ float: 'right' }}>
              <Select sx={{}} placeholder="View All">
                <option value="option1">Option 1</option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
              </Select>
            </div>
          </Box>
          <Box sx={{
        paddingLeft: '10px',
    }}>
            <div style={{ float: 'left', paddingTop: '8px', paddingRight: '5px' }}>
              {t('common:SORT_BY')}
            </div>
            <div style={{ float: 'right' }}>
              <Select sx={{}} placeholder="Run date, Newest">
                <option value="option1">Option 1</option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
              </Select>
            </div>
          </Box>
          <Box />
        </Flex>
        <Flex textAlign="left" sx={{
        flexDirection: 'column',
        backgroundColor: '#C8D4DD52',
        paddingLeft: '200px',
        paddingRight: '200px',
    }}>
          <MisRepSection title={t('policy-management/misrep:MIS_REP_FOUND')} description={t('policy-management/misrep:MIS_REP_FOUND_DESC')} misrepResults={misrepresentation} misrepLabel={t('policy-management/misrep:MIS_REP_LABEL')} misrepLabelColour={MisrepColours.MISREP} policyReference={policyReference} runId={runId} dataTestId="misrep-found-section"/>

          <MisRepSection title={t('policy-management/misrep:NO_RECORDS')} description={t('policy-management/misrep:NO_RECORDS_DESCRIPTION')} misrepResults={noMisrepFound} misrepLabel={t('policy-management/misrep:NO_RECORDS_LABEL')} misrepLabelColour={MisrepColours.NO_MISREP} policyReference={policyReference} runId={runId} dataTestId="no-record-section"/>
        </Flex>
      </MisRepContext.Provider>
    </>);
};
export default MisRepReport;

import { Badge, Box, Flex } from '@chakra-ui/react';
import React from 'react';
import { RunStatus } from '@/domain/Run/Run';
function getStatusColor(value) {
    switch (value) {
        case RunStatus.MISREP.toString():
            return 'brand.red';
        case RunStatus.PASSED.toString():
            return 'brand.green';
        default:
            return 'brand.blue';
    }
}
var MisrepTitle = function (_a) {
    var policyReference = _a.policyReference, status = _a.status;
    return (<>
      <Flex sx={{ justifyContent: 'center' }}>
        <Box sx={{
        padding: '.1rem',
        borderRadius: '.1rem',
        minWidth: '90%',
        textAlign: 'center',
    }}>
          <div data-testid="misrep-title" style={{
        fontSize: '20px',
        fontWeight: 'bold',
    }}>
            {policyReference} Disclosures
          </div>
        </Box>
        <Badge as="div" variant="solid" colorScheme={getStatusColor(status)} sx={{
        padding: '.5rem .1rem',
        borderRadius: '.5rem',
        minWidth: '30%',
        textAlign: 'center',
    }}>
          {status}
        </Badge>
      </Flex>
    </>);
};
export default MisrepTitle;

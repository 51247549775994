var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { POLICY_SERVICE_API } from '@/constants/app';
import { RunStatus } from '@/domain/Run/Run';
import axios from '@/libs/axiosConfig/axiosConfig';
import { makeQueryParams } from '@/libs/urlParams/urlParams';
export function getRuns(_a) {
    var _b = _a.params, params = _b === void 0 ? '' : _b, _c = _a.tenant, tenant = _c === void 0 ? null : _c, _d = _a.token, token = _d === void 0 ? '' : _d;
    return __awaiter(this, void 0, Promise, function () {
        var data;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0: return [4 /*yield*/, axios.get(POLICY_SERVICE_API + "/v2/tenant/" + tenant + "/runs?" + params, {
                        headers: { Authorization: "Bearer " + token },
                    })];
                case 1:
                    data = (_e.sent()).data;
                    return [2 /*return*/, data];
            }
        });
    });
}
export function downloadMisrepResultsAsFile(_a) {
    var policyReference = _a.policyReference, runId = _a.runId, _b = _a.tenant, tenant = _b === void 0 ? null : _b, token = _a.token;
    return __awaiter(this, void 0, Promise, function () {
        var response, url, link;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, axios.get(POLICY_SERVICE_API + "/v1/tenant/" + tenant + "/policies/" + policyReference + "/runs/" + runId + "/misrep/outputs/misRepV1", {
                        headers: { Authorization: "Bearer " + token },
                        responseType: 'blob',
                    })];
                case 1:
                    response = _c.sent();
                    url = window.URL.createObjectURL(new Blob([response.data]));
                    link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', policyReference + ".csv"); // Replace with your filename and extension
                    document.body.appendChild(link);
                    link.click();
                    // Clean up
                    URL.revokeObjectURL(url);
                    link.remove();
                    return [2 /*return*/];
            }
        });
    });
}
export function downloadEnquiryComparisonResultsAsFile(_a) {
    var policyReference = _a.policyReference, runId = _a.runId, _b = _a.tenant, tenant = _b === void 0 ? null : _b, token = _a.token;
    return __awaiter(this, void 0, Promise, function () {
        var response, url, link;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, axios.get(POLICY_SERVICE_API + "/v1/tenant/" + tenant + "/policies/" + policyReference + "/runs/" + runId + "/outputs/decisionsComparisonV1", {
                        headers: { Authorization: "Bearer " + token },
                        responseType: 'blob',
                    })];
                case 1:
                    response = _c.sent();
                    url = window.URL.createObjectURL(new Blob([response.data]));
                    link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', policyReference + "-" + runId + ".csv");
                    document.body.appendChild(link);
                    link.click();
                    URL.revokeObjectURL(url);
                    link.remove();
                    return [2 /*return*/];
            }
        });
    });
}
export function getMisrepResults(_a) {
    var policyReference = _a.policyReference, runId = _a.runId, _b = _a.tenant, tenant = _b === void 0 ? null : _b, token = _a.token;
    return __awaiter(this, void 0, Promise, function () {
        var data;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, axios.get(POLICY_SERVICE_API + "/v1/tenant/" + tenant + "/policies/" + policyReference + "/runs/" + runId + "/misrep", {
                        headers: { Authorization: "Bearer " + token },
                    })];
                case 1:
                    data = (_c.sent()).data;
                    return [2 /*return*/, data];
            }
        });
    });
}
export function listPolicies(_a) {
    var _b = _a.params, params = _b === void 0 ? '' : _b, _c = _a.tenant, tenant = _c === void 0 ? null : _c, token = _a.token;
    return __awaiter(this, void 0, Promise, function () {
        var data;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0: return [4 /*yield*/, axios.get(POLICY_SERVICE_API + "/v2/tenant/" + tenant + "/policies?" + params, {
                        headers: { Authorization: "Bearer " + token },
                    })];
                case 1:
                    data = (_d.sent()).data;
                    return [2 /*return*/, data];
            }
        });
    });
}
export function getStatusWithMisrepResult(_a) {
    var policyReference = _a.policyReference, runId = _a.runId, _b = _a.tenant, tenant = _b === void 0 ? null : _b, token = _a.token, currentStatus = _a.currentStatus;
    return __awaiter(this, void 0, Promise, function () {
        var misRepResults, status, isThereAnyActiveMisrep;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, getMisrepResults({
                        policyReference: policyReference,
                        runId: runId,
                        tenant: tenant,
                        token: token,
                    })];
                case 1:
                    misRepResults = _c.sent();
                    status = currentStatus;
                    if (status === RunStatus.FAILED) {
                        status = RunStatus.ERROR;
                    }
                    if (status === RunStatus.PROCESSED) {
                        isThereAnyActiveMisrep = misRepResults.filter(function (result) {
                            return result.foundedEvidences.find(function (result) { return !result.disagreed; });
                        }).length;
                        if (isThereAnyActiveMisrep > 0) {
                            status = RunStatus.MISREP;
                        }
                        else if (isThereAnyActiveMisrep === 0) {
                            status = RunStatus.PASSED;
                        }
                    }
                    return [2 /*return*/, status];
            }
        });
    });
}
export function saveDisagreedEvidence(disagreedEvidence, policyReference, runId, token, tenant) {
    return __awaiter(this, void 0, Promise, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, axios.put(POLICY_SERVICE_API + "/v1/tenant/" + tenant + "/policies/" + policyReference + "/runs/" + runId + "/misrep/updateEvidence", disagreedEvidence, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: "Bearer " + token,
                    },
                })];
        });
    });
}
export function saveFeedback(feedback, policyReference, runId, questionId, token, tenant) {
    return __awaiter(this, void 0, Promise, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, axios.post(POLICY_SERVICE_API + "/v1/tenant/" + tenant + "/policies/" + policyReference + "/runs/" + runId + "/misrep/questions/" + questionId + "/feedback", feedback, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: "Bearer " + token,
                    },
                })];
        });
    });
}
export function listPoliciesAndRun(_a) {
    var _b = _a.params, params = _b === void 0 ? '' : _b, _c = _a.tenant, tenant = _c === void 0 ? '' : _c, token = _a.token;
    return __awaiter(this, void 0, Promise, function () {
        var _d, firstPageTotalPages, firstPageNumber, firstPageContent, firstPageElements, getPolicyWithStatus, policy, nextParams, allRuns, run, policy, policyReference, runId, currentStatus, _e;
        return __generator(this, function (_f) {
            switch (_f.label) {
                case 0: return [4 /*yield*/, listPolicies({ params: params, tenant: tenant, token: token })];
                case 1:
                    _d = _f.sent(), firstPageTotalPages = _d.totalPages, firstPageNumber = _d.number, firstPageContent = _d.content, firstPageElements = _d.totalElements;
                    getPolicyWithStatus = [];
                    for (policy = 0; policy < firstPageContent.length; policy += 1) {
                        nextParams = makeQueryParams({
                            page: 0,
                            size: 20,
                            searchText: firstPageContent[policy].policyReference,
                            exact: true,
                        });
                        getPolicyWithStatus.push(getRuns({ params: nextParams, tenant: tenant, token: token }));
                    }
                    return [4 /*yield*/, Promise.all(getPolicyWithStatus)];
                case 2:
                    allRuns = _f.sent();
                    run = 0;
                    _f.label = 3;
                case 3:
                    if (!(run < allRuns.length)) return [3 /*break*/, 8];
                    if (!(allRuns[run].content.length > 0)) return [3 /*break*/, 7];
                    policy = 0;
                    _f.label = 4;
                case 4:
                    if (!(policy < firstPageContent.length)) return [3 /*break*/, 7];
                    if (!(allRuns[run].content[0].policyReference ===
                        firstPageContent[policy].policyReference)) return [3 /*break*/, 6];
                    firstPageContent[policy].runId = allRuns[run].content[0].runId;
                    firstPageContent[policy].creationDate =
                        allRuns[run].content[0].creationDate;
                    policyReference = firstPageContent[policy].policyReference;
                    runId = firstPageContent[policy].runId;
                    currentStatus = allRuns[run].content[0].status;
                    _e = firstPageContent[policy];
                    return [4 /*yield*/, getStatusWithMisrepResult({
                            policyReference: policyReference,
                            runId: runId,
                            tenant: tenant,
                            token: token,
                            currentStatus: currentStatus,
                        })];
                case 5:
                    _e.status = _f.sent();
                    firstPageContent[policy].details = firstPageContent[policy].product + ", " + firstPageContent[policy].sex + ", " + firstPageContent[policy].age;
                    _f.label = 6;
                case 6:
                    policy += 1;
                    return [3 /*break*/, 4];
                case 7:
                    run += 1;
                    return [3 /*break*/, 3];
                case 8: 
                /* eslint-enable no-await-in-loop */
                return [2 /*return*/, {
                        content: firstPageContent,
                        number: firstPageNumber,
                        totalPages: firstPageTotalPages,
                        totalElements: firstPageElements,
                    }];
            }
        });
    });
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
/* eslint-disable react/jsx-props-no-spreading */
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { AspectRatio, Button, Container, Flex, Stack } from '@chakra-ui/react';
import React, { useCallback, useEffect, useRef, useState, } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCheckCircle } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import AlertDialog from '@/components/AlertDialog/AlertDialog';
import ApplicationDataForm from '@/components/Form/AppData/ApplicationDataForm';
import EnquiryForm from '@/components/Form/Enquiry/EnquiryForm';
import PolicyDetailsForm from '@/components/Form/Policy/PolicyDetailsForm';
import Stepper from '@/components/Form/Stepper';
import { useAuthToken } from '@/domain/Auth/hooks/useAuthToken/useAuthToken';
import { Product, Sex, } from '@/domain/Policy/Policy';
import { createPolicy, doModelRunForAPolicy, startEnquiryProcess, updatePolicy, uploadPolicyAppForm, } from '@/domain/Policy/PolicyService';
import useTenantContext from '@/domain/Tenancy/hooks/useTenantContext/useTenantContext';
import getTouchedErrorObjects from '@/libs/formik/utils';
import DocumentUploadComponent from '@/pages/evidence-upload/DocumentUploadComponent';
import appFormCsvPath from '../../../data/App_Form_Questions.csv';
import parseCsvToAppFormData from './CsvUtils';
var CreatePolicyPage = function () {
    var t = useTranslation([
        'common',
        'policy-management/create',
        'policy-management/enquiry',
        'policy-management/appform',
        'evidence-upload/document',
    ]).t;
    var history = useHistory();
    var initialPolicyDetailsValues = {
        policyReference: '',
        age: '',
        sex: Sex.MALE,
        product: Product.LIFE,
        applicationDate: '',
    };
    var initialEnquiryDetailsValues = {
        enquiryId: '',
    };
    var _a = useState(initialPolicyDetailsValues), policyDetails = _a[0], setPolicyDetails = _a[1];
    var _b = useState(initialEnquiryDetailsValues), enquiryDetails = _b[0], setEnquiryDetails = _b[1];
    var _c = useState(false), isValidEnquiry = _c[0], setIsValidEnquiry = _c[1];
    var initialiseAppForm = function (policyAppForm) {
        return policyAppForm.groupedQuestions
            .flatMap(function (group) {
            return group.questions.map(function (question) {
                var _a;
                var initialValue = (question === null || question === void 0 ? void 0 : question.answerType) === 'Boolean' ? 'false' : '';
                return _a = {}, _a[question.id] = initialValue, _a;
            });
        })
            .reduce(function (acc, question) { return (__assign(__assign({}, acc), question)); }, {});
    };
    var _d = useState({
        groupedQuestions: [],
    }), filteredPolicyAppForm = _d[0], setFilteredPolicyAppForm = _d[1];
    var _e = useState({
        groupedQuestions: [],
    }), originalPolicyAppForm = _e[0], setOriginalPolicyAppForm = _e[1];
    var _f = useState({}), appFormValues = _f[0], setAppFormValues = _f[1];
    var tenant = useTenantContext().tenant;
    var token = useAuthToken().token;
    var _g = useState(''), policyReference = _g[0], setPolicyReference = _g[1];
    var _h = useState(''), enquiryId = _h[0], setEnquiryId = _h[1];
    var policyFormikRef = useRef(null);
    var enquiryFormikRef = useRef(null);
    var appFormFormikRef = useRef(null);
    var StepName;
    (function (StepName) {
        StepName[StepName["POLICY_DETAILS"] = 0] = "POLICY_DETAILS";
        StepName[StepName["ENQUIRY_ID"] = 1] = "ENQUIRY_ID";
        StepName[StepName["APP_FORM"] = 2] = "APP_FORM";
        StepName[StepName["EVIDENCE_UPLOAD"] = 3] = "EVIDENCE_UPLOAD";
    })(StepName || (StepName = {}));
    var totalSteps = 4;
    var _j = useState(StepName.POLICY_DETAILS), currentStep = _j[0], setCurrentStep = _j[1];
    var _k = useState(false), isEvidenceUploadSubmitting = _k[0], setIsEvidenceUploadSubmitting = _k[1];
    var _l = useState([]), documentUploadList = _l[0], setDocumentUploadList = _l[1];
    var _m = useState(''), scheduledPolicyReferences = _m[0], setScheduledPolicyReferences = _m[1];
    var doScheduleModelRun = function (tenant) { return __awaiter(void 0, void 0, void 0, function () {
        var uniquePolicyRefList, requests;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsEvidenceUploadSubmitting(true);
                    uniquePolicyRefList = Array.from(new Set(__spreadArrays(documentUploadList).flatMap(function (_a) {
                        var policyReference = _a.policyReference;
                        return policyReference;
                    })));
                    requests = uniquePolicyRefList.map(function (ref) {
                        return doModelRunForAPolicy(ref, tenant, token);
                    });
                    return [4 /*yield*/, Promise.allSettled(requests)];
                case 1:
                    _a.sent();
                    setScheduledPolicyReferences(uniquePolicyRefList.join(', '));
                    setIsEvidenceUploadSubmitting(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var doStartEnquiryProcess = function (tenant) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, startEnquiryProcess(tenant, token, policyReference, enquiryId)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var updatePolicyByEnquiryId = function (policy, enquiryId) { return __awaiter(void 0, void 0, void 0, function () {
        var policyReference, age, sex, product, applicationDate, expectedPolicyOutcome, createPolicy;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    policyReference = policy.policyReference, age = policy.age, sex = policy.sex, product = policy.product, applicationDate = policy.applicationDate, expectedPolicyOutcome = policy.expectedPolicyOutcome;
                    createPolicy = {
                        age: age,
                        sex: sex,
                        product: product,
                        applicationDate: applicationDate,
                        expectedPolicyOutcome: expectedPolicyOutcome,
                        enquiryId: enquiryId,
                    };
                    return [4 /*yield*/, updatePolicy(policyReference, createPolicy, tenant, token)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    function filterBasedOnPolicy(policyDetails, policyAppForm) {
        var excludedGender = '';
        var products = [''];
        if (policyDetails.sex === Sex.FEMALE) {
            excludedGender = 'Males only';
        }
        else if (policyDetails.sex === Sex.MALE) {
            excludedGender = 'Females only';
        }
        if (policyDetails.product === 'Life') {
            products = ['LIFE'];
        }
        else if (policyDetails.product === 'CI') {
            products = ['LIFE', 'CI'];
        }
        var filteredQuestions = policyAppForm.groupedQuestions
            .map(function (item) { return (__assign(__assign({}, item), { questions: item.questions
                .filter(function (question) { return question.gender !== excludedGender; })
                .filter(function (question) {
                return parseInt(policyDetails.age, 10) <= 55
                    ? question.age !== 'Over age 55'
                    : question;
            })
                .filter(function (question) {
                return question.product !== undefined &&
                    products.includes(question.product);
            }) })); })
            .filter(function (questionGroups) { return questionGroups.questions.length > 0; });
        var updatedPolicyAppForm = __assign(__assign({}, policyAppForm), { groupedQuestions: filteredQuestions });
        setFilteredPolicyAppForm(updatedPolicyAppForm);
        var appFormValues = initialiseAppForm(updatedPolicyAppForm);
        setAppFormValues(appFormValues);
    }
    var handleNext = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, policyFormError, isValid, enquiryError, isValid;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _a = currentStep;
                    switch (_a) {
                        case StepName.POLICY_DETAILS: return [3 /*break*/, 1];
                        case StepName.ENQUIRY_ID: return [3 /*break*/, 4];
                        case StepName.APP_FORM: return [3 /*break*/, 7];
                        case StepName.EVIDENCE_UPLOAD: return [3 /*break*/, 10];
                    }
                    return [3 /*break*/, 11];
                case 1:
                    if (!policyFormikRef.current) return [3 /*break*/, 3];
                    return [4 /*yield*/, policyFormikRef.current.validateForm()];
                case 2:
                    policyFormError = _b.sent();
                    policyFormikRef.current.setTouched(getTouchedErrorObjects(policyFormError));
                    isValid = policyFormikRef.current.isValid;
                    if (isValid) {
                        setPolicyDetails(policyFormikRef.current.values);
                        filterBasedOnPolicy(policyFormikRef.current.values, originalPolicyAppForm);
                        setCurrentStep(StepName.ENQUIRY_ID);
                    }
                    _b.label = 3;
                case 3: return [3 /*break*/, 12];
                case 4:
                    if (!enquiryFormikRef.current) return [3 /*break*/, 6];
                    return [4 /*yield*/, enquiryFormikRef.current.validateForm()];
                case 5:
                    enquiryError = _b.sent();
                    enquiryFormikRef.current.setTouched(getTouchedErrorObjects(enquiryError));
                    if (isValidEnquiry) {
                        setEnquiryDetails(enquiryFormikRef.current.values);
                        setCurrentStep(StepName.EVIDENCE_UPLOAD);
                        setIsValidEnquiry(true);
                    }
                    else {
                        setCurrentStep(StepName.APP_FORM);
                        setIsValidEnquiry(false);
                    }
                    _b.label = 6;
                case 6: return [3 /*break*/, 12];
                case 7:
                    if (!appFormFormikRef.current) return [3 /*break*/, 9];
                    return [4 /*yield*/, appFormFormikRef.current.validateForm()];
                case 8:
                    _b.sent();
                    isValid = appFormFormikRef.current.isValid;
                    if (isValid) {
                        setAppFormValues(appFormFormikRef.current.values);
                        setCurrentStep(StepName.EVIDENCE_UPLOAD);
                    }
                    _b.label = 9;
                case 9: return [3 /*break*/, 12];
                case 10:
                    {
                        doScheduleModelRun(tenant);
                        return [3 /*break*/, 12];
                    }
                    _b.label = 11;
                case 11:
                    {
                        history.push('/');
                    }
                    _b.label = 12;
                case 12: return [2 /*return*/];
            }
        });
    }); }, [
        currentStep,
        doScheduleModelRun,
        tenant,
        history,
        doStartEnquiryProcess,
        updatePolicyByEnquiryId,
    ]);
    var handleBack = useCallback(function () {
        switch (currentStep) {
            case StepName.ENQUIRY_ID: {
                setCurrentStep(StepName.POLICY_DETAILS);
                break;
            }
            case StepName.APP_FORM: {
                if (appFormFormikRef.current) {
                    setAppFormValues(appFormFormikRef.current.values);
                    setCurrentStep(StepName.ENQUIRY_ID);
                }
                break;
            }
            case StepName.EVIDENCE_UPLOAD: {
                if (isValidEnquiry) {
                    setCurrentStep(StepName.ENQUIRY_ID);
                }
                else {
                    setCurrentStep(StepName.APP_FORM);
                }
                break;
            }
            default: {
                history.push('/');
            }
        }
    }, [currentStep, history]);
    var mapAnsweredAppFormQuestions = function (policyAppForm, appFormValues) {
        var answeredQuestions = [];
        policyAppForm.groupedQuestions.forEach(function (group) {
            return group.questions.forEach(function (question) {
                var answeredQuestion = {
                    id: question.id,
                    question: question.question,
                    answerType: question.answerType,
                    answerValue: appFormValues[question.id],
                    timeFrameDays: question.timeFrameDays,
                    snomedId: question.snomedId,
                    negation: question.negation,
                };
                answeredQuestions.push(answeredQuestion);
            });
        });
        return answeredQuestions;
    };
    var savePolicy = function () { return __awaiter(void 0, void 0, void 0, function () {
        var policyReference, encodedPolicyReference, policyFormData, answeredQuestions;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    policyReference = policyDetails.policyReference;
                    encodedPolicyReference = encodeURI(policyReference);
                    policyFormData = __assign(__assign({}, policyDetails), { policyReference: encodedPolicyReference });
                    return [4 /*yield*/, createPolicy(policyFormData, tenant, token)];
                case 1:
                    _a.sent();
                    if (!isValidEnquiry) return [3 /*break*/, 2];
                    updatePolicyByEnquiryId(policyDetails, enquiryId);
                    doStartEnquiryProcess(tenant);
                    return [3 /*break*/, 4];
                case 2:
                    answeredQuestions = mapAnsweredAppFormQuestions(filteredPolicyAppForm, appFormValues);
                    return [4 /*yield*/, uploadPolicyAppForm(encodedPolicyReference, answeredQuestions, tenant, token)];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var getFormForCurrentStep = function (number) {
        switch (number) {
            case StepName.POLICY_DETAILS: {
                return (<div>
            <PolicyDetailsForm formikRef={policyFormikRef} formValues={policyDetails} policyReference={policyReference} setPolicyReference={setPolicyReference}/>
          </div>);
            }
            case StepName.ENQUIRY_ID: {
                return (<div>
            <EnquiryForm formikRef={enquiryFormikRef} formValues={enquiryDetails} policyReference={policyReference} enquiryId={enquiryId} setEnquiryId={setEnquiryId} setIsValidEnquiry={setIsValidEnquiry}/>
          </div>);
            }
            case StepName.APP_FORM: {
                return (<div>
            <ApplicationDataForm formikRef={appFormFormikRef} policyAppForm={filteredPolicyAppForm} formValues={appFormValues}/>
          </div>);
            }
            case StepName.EVIDENCE_UPLOAD: {
                return (<div>
            <DocumentUploadComponent documentUploadList={documentUploadList} setDocumentUploadList={setDocumentUploadList} policyReference={policyReference} onDocumentUpload={savePolicy} isSubmitting={isEvidenceUploadSubmitting}/>
          </div>);
            }
            default: {
                return (<div>
            <p>Something has gone wrong, please refresh page.</p>
          </div>);
            }
        }
    };
    var getForwardButtonText = function (number) {
        switch (number) {
            case StepName.POLICY_DETAILS: {
                return t('policy-management/create:FORM.CTA.POLICY_DETAILS.NEXT');
            }
            case StepName.ENQUIRY_ID: {
                if (isValidEnquiry) {
                    return t('policy-management/create:FORM.CTA.ENQUIRY_PAGE.NEXT_TO_EVIDENCE');
                }
                return t('policy-management/create:FORM.CTA.ENQUIRY_PAGE.NEXT_TO_APP_FORM');
            }
            case StepName.APP_FORM: {
                return t('policy-management/create:FORM.CTA.APP_FORM.NEXT');
            }
            case StepName.EVIDENCE_UPLOAD: {
                return t('policy-management/create:FORM.CTA.EVIDENCE_UPLOAD.NEXT');
            }
            default: {
                return (<div>
            <p>Something has gone wrong, please refresh page.</p>
          </div>);
            }
        }
    };
    var getBackButtonText = function (number) {
        switch (number) {
            case StepName.POLICY_DETAILS: {
                return t('policy-management/create:FORM.CTA.POLICY_DETAILS.BACK');
            }
            case StepName.ENQUIRY_ID: {
                return t('policy-management/create:FORM.CTA.ENQUIRY_PAGE.BACK');
            }
            case StepName.APP_FORM: {
                return t('policy-management/create:FORM.CTA.APP_FORM.BACK');
            }
            case StepName.EVIDENCE_UPLOAD: {
                if (isValidEnquiry) {
                    return t('policy-management/create:FORM.CTA.EVIDENCE_UPLOAD.BACK_TO_ENQUIRY');
                }
                return t('policy-management/create:FORM.CTA.EVIDENCE_UPLOAD.BACK');
            }
            default: {
                return (<div>
            <p>Something has gone wrong, please refresh page.</p>
          </div>);
            }
        }
    };
    useEffect(function () {
        var isMounted = true;
        if (isMounted) {
            fetch(appFormCsvPath)
                .then(function (response) { return response.text(); })
                .then(function (text) { return parseCsvToAppFormData(text); })
                .then(function (mappedData) {
                var appFormDetails = { groupedQuestions: mappedData };
                setFilteredPolicyAppForm(appFormDetails);
                setOriginalPolicyAppForm(appFormDetails);
                var appFormValues = initialiseAppForm(appFormDetails);
                setAppFormValues(appFormValues);
            });
        }
        return function () {
            isMounted = false;
        };
    }, []);
    return (<>
      <Container sx={{
        border: '0px',
        borderRadius: 5,
        p: 5,
        maxW: '100%',
        boxShadow: 'sm',
    }}>
        {getFormForCurrentStep(currentStep)}
        <Flex>
          <AspectRatio sx={{ flex: 2 }} ratio={10}>
            <Container centerContent>
              <Stack direction="row" spacing={4} align="center">
                {currentStep !== 0 && (<Button colorScheme="teal" variant="outline" sx={{ align: 'center' }} leftIcon={<ArrowBackIcon />} type="button" onClick={handleBack}>
                    {getBackButtonText(currentStep)}
                  </Button>)}

                <Button data-testid="next-button" colorScheme="teal" variant="solid" sx={{ align: 'center' }} loadingText={t('policy-management/create:FORM.CTA.SUBMITTING')} rightIcon={<ArrowForwardIcon />} type="button" onClick={handleNext}>
                  {getForwardButtonText(currentStep)}
                </Button>
              </Stack>
            </Container>
          </AspectRatio>
        </Flex>
        <Stepper totalSteps={totalSteps} currentStep={currentStep}/>
        {scheduledPolicyReferences !== '' && (<AlertDialog onCloseCallback={function () {
        setScheduledPolicyReferences('');
        setDocumentUploadList([]);
        history.push('/policy-management');
    }} color="brand.status.success" headerText={t('evidence-upload/document:DIALOG.HDR.SUCCESS')} bodyText={[
        t('evidence-upload/document:DIALOG.BODY.SUBMITTED_FOR_MODEL_RUN', {
            scheduledPolicyReferences: scheduledPolicyReferences,
        }),
    ]} ctaText={t('evidence-upload/document:DIALOG.CTA.CONTINUE')} icon={FaCheckCircle}/>)}
      </Container>
    </>);
};
export default CreatePolicyPage;
